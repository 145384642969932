// Usage:
//const result1 = new Str('1231231.32423423').decimal(5);
//const result2 = new Str('2234.232').parseInt(); // Result: 2234
class Str {
    constructor(text) {
        this.inputString = typeof text === 'string' ? text : String(text);
    }

    decimal(decimalPlaces = 2) {
        this.numericString = this.inputString.replace(/[^\d.]/g, '');
        return parseFloat(this.numericString).toFixed(decimalPlaces);
    }
    parseDecimal(decimalPlaces = null) {
        let numericString = this.inputString.replace(/[^\d.]/g, '');
        let float = parseFloat(numericString);

        if (isNaN(float)) {
            return null;
        }

        return decimalPlaces === null ? float : Number(float.toFixed(decimalPlaces));
    }

    parseInt() {
        let numericString = this.inputString.replace(/[^\d-]/g, '');
        let int = parseInt(numericString);

        if (isNaN(int)) {
            return null;
        }

        return Math.round(int);
    }
    removeDiacritics() {
        return this.inputString
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    }
    clearString() {
        const quitarpalabras = ["a", "de", "un", "que", "es", "con", "el", "la", "los", "las", "una", "unos", "en"];
        this.inputString = (this.inputString || "").toUpperCase();
        quitarpalabras.forEach((value) => {
            this.inputString = this.inputString.replace(new RegExp(`\\s+${value}\\s+`, "gi"), " ");
        });
        this.inputString = this.inputString.replace(/\s+/gi, " ");
        this.inputString = this.inputString.replace(/\s/gi, "_");
        this.inputString = this.stripVowelAccent();
        this.inputString = this.inputString.replace(/[^a-zA-Z0-9_]/gi, '');
        this.inputString = this.inputString.length > 121 ? this.inputString.substr(0, 120) : this.inputString;
        return this.inputString.toUpperCase();
    }
    stripVowelAccent() {
        const rExps = [
            { re: /[\xC0-\xC6]/g, ch: 'A' },
            { re: /[\xE0-\xE6]/g, ch: 'a' },
            { re: /[\xC8-\xCB]/g, ch: 'E' },
            { re: /[\xE8-\xEB]/g, ch: 'e' },
            { re: /[\xCC-\xCF]/g, ch: 'I' },
            { re: /[\xEC-\xEF]/g, ch: 'i' },
            { re: /[\xD2-\xD6]/g, ch: 'O' },
            { re: /[\xF2-\xF6]/g, ch: 'o' },
            { re: /[\xD9-\xDC]/g, ch: 'U' },
            { re: /[\xF9-\xFC]/g, ch: 'u' },
            { re: /[\xD1]/g, ch: 'N' },
            { re: /[\xF1]/g, ch: 'n' }
        ];
        this.inputString = (this.inputString || "");
        for (let i = 0, len = rExps.length; i < len; i++) {
            this.inputString = this.inputString.replace(rExps[i].re, rExps[i].ch);
        }
        return this.inputString;
    }
    decimalToString(decimals) {
        var numString = this.inputString + ''; // convertimos a un string
        var numFloat = parseFloat(numString.replace(/[^0-9\-.]/g, ''));

        if (isNaN(numFloat))
            return '';

        if (numFloat === 0)
            return '0';

        numString = '' + numFloat.toFixed(decimals);

        var numParts = numString.split('.'), regexp = /(\d+)(\d{3})/;

        while (regexp.test(numParts[0]))
            numParts[0] = numParts[0].replace(regexp, '$1' + ',' + '$2');

        return numParts.join('.');
    }
}

export default Str;