﻿export const FileUploadService = (function ($) {
    let instance = null;

    class FileUploader {
        constructor() {
            this.$modal = null;
            this.kendoWindow = null;
            this.initialized = false;
            this.currentSettings = null;
            this.currentUploader = null;
            this.isSelecting = false;
            this.templates = {
                file: `
                    <span class='k-progress'></span>
                    <div class='file-wrapper w-100'>
                        <div class="fileUpload-document-info-container" style="display: grid; grid-template-columns: 60% 20% auto; font-family: Roboto;font-size: 9px;font-weight: 500;line-height: 12.39px;text-align: left;align-items: center;">
                            <div class='file-header-table'>Nombre</div>
                            <div class='file-header-table'>Tamaño</div>
                            <div class='file-header-table'>Actions</div>

                            <div class='file-heading file-name-heading d-flex align-items-center gap-2'>
                                <span class='pg pg-policies mr-4'></span> #=name#
                            </div>
                            <div class='file-heading file-size-heading'>
                                #=(size / 1024).toFixed(2)# KB
                            </div>

                            <strong class="k-upload-status">
                                <button type='button' class='k-upload-action'></button>
                                <button type='button' class='k-upload-action'></button>
                            </strong>
                        </div>
                    </div>
                `,
                modal: `
                    <div id="fileUploadModal" style="display: none;">
                        <div class="demo-section">
                            <input type="file" name="kendoFileUpload" id="kendoFileUpload" />
                        </div>
                        <div class="w-100 d-flex justify-content-center">
                            <div id="loadingSpinner" style="display: none;"></div>
                        </div>
                        <div style="border-top: 1px solid rgb(229, 230, 232);" class="d-flex w-100 pt-3">
                            <div id="uploadResults" style="font-family: Roboto; font-size: 9px; font-weight: 500; line-height: 12.39px; text-align: left; width: 100%;">
                                <div>Resumen</div>
                                <div class="d-flex justify-content-between flex-column">
                                    <div class="text-success"><strong>Insertados:</strong> <span id="insertedRecords"></span></div>
                                    <div class="text-info"><strong>Modificados:</strong> <span id="modifiedRecords"></span></div>
                                    <div class="text-danger"><strong>Errores:</strong> <span id="errors"></span> <a href="#" id="toggleErrorDetails">[Ver Detalles]</a></div>
                                </div>
                            </div>
                            <div id="fileInportButtons" class="justify-content-end align-items-start gap-2 w-100" style="display: none;">
                                <button id="closeButton" class="btn">Cerrar</button>
                                <button id="importButton" class="btn">Importar</button>
                            </div>
                        </div>
                        <div id="errorDetails" class="list-unstyled font-weight-light pt-3 mt-4" style="display: none; font-family: Roboto;font-size: 9px;font-weight: 500;line-height: 12.39px;text-align: left;color: #9095A0;border-top: 1px solid #E5E6E8;"></div>
                    </div>
                `
            };
        }

        async initialize() {
            try {
                await this.createContainer();
                const success = await this.initializeModal();
                if (success) {
                    this.initializeEventHandlers();
                    this.initialized = true;
                }
            } catch (error) {
                console.error('Error durante la inicialización:', error);
            }
        }

        async createContainer() {
            $('#fileUploadModal').remove();
            $('#globalFileUploadContainer').remove();

            const containerId = 'globalFileUploadContainer_' + Math.random().toString(36).substr(2, 9);
            $('body').append(`<div id="${containerId}"></div>`);
            $('#' + containerId).html(this.templates.modal);

            this.$modal = $('#fileUploadModal');
            return this.$modal.length > 0;
        }

        initializeModal() {
            return new Promise((resolve) => {
                if (!this.$modal || !this.$modal.length) {
                    console.error('Modal element no encontrado');
                    resolve(false);
                    return;
                }

                try {
                    this.$modal.kendoWindow({
                        width: "500px",
                        title: "Subir archivo",
                        visible: false,
                        actions: ["Close"],
                        modal: true,
                        resizable: false,
                        draggable: true,
                        animation: false,
                        open: () => {
                            this.isSelecting = false;
                        },
                        close: () => {
                            this.clearResults();
                            this.isSelecting = false;
                            if (this.currentUploader) {
                                const uploadContainerElement = this.$modal.find(".demo-section");
                                uploadContainerElement.empty();
                                uploadContainerElement.html('<input type="file" name="kendoFileUpload" id="kendoFileUpload" />');
                                this.currentUploader = null;
                            }
                        }
                    });

                    this.kendoWindow = this.$modal.data("kendoWindow");

                    this.$modal.find('#loadingSpinner').kendoLoader({
                        size: 'medium',
                        themeColor: "primary"
                    });

                    resolve(true);
                } catch (error) {
                    console.error('Error al inicializar el modal:', error);
                    resolve(false);
                }
            });
        }

        initializeEventHandlers() {
            if (!this.$modal) return;

            this.$modal.on("click", "#closeButton", () => {
                if (this.kendoWindow) {
                    this.clearResults();
                    this.kendoWindow.close();
                }
            });

            this.$modal.on("click", "#importButton", () => {
                if (this.currentUploader) {
                    this.currentUploader.upload();
                }
            });

            this.$modal.on("click", "#toggleErrorDetails", (e) => {
                e.preventDefault();
                const $errorDetails = this.$modal.find("#errorDetails");
                $errorDetails.toggle();
                const $link = $(e.target);
                $link.text($errorDetails.is(":visible") ? "[Ocultar Detalles]" : "[Ver Detalles]");
            });
        }

        setupFileUploader(settings) {
            const uploadContainerElement = this.$modal.find(".demo-section");
            uploadContainerElement.empty();

            uploadContainerElement.html('<input type="file" name="kendoFileUpload" id="kendoFileUpload" />');

            const uploadElement = this.$modal.find("#kendoFileUpload");

            const kendoUploadOptions = {
                async: {
                    saveUrl: this.getUrl(settings.url),
                    autoUpload: false
                },
                multiple: false,
                localization: {
                    select: "Seleccionar archivo",
                    dropFilesHere: "-o- Arrastre su documento aquí",
                    uploadSelectedFiles: "Subir Archivos",
                    clearSelectedFiles: "Limpiar",
                    statusUploading: "Subiendo...",
                    statusUploaded: "Completado",
                    statusFailed: "Error"
                },
                validation: {
                    allowedExtensions: settings.allowedExtensions,
                    maxFileSize: 10485760
                },
                template: kendo.template(this.templates.file),
                select: (e) => {
                    if (this.isSelecting) {
                        e.preventDefault();
                        return;
                    }

                    this.isSelecting = true;
                    setTimeout(() => {
                        this.isSelecting = false;
                    }, 500);

                    const file = e.files[0];
                    if (!file) return;

                    const ext = file.extension.toLowerCase();
                    if (settings.allowedExtensions.indexOf(ext) === -1) {
                        e.preventDefault();
                        Alert.error(`Tipo de archivo no permitido. Use: ${settings.allowedExtensions.join(", ")}`);
                        return;
                    }

                    this.$modal.find("#fileInportButtons").addClass("d-flex");
                },
                upload: (e) => this.handleFileUpload(e, settings),
                clear: () => {
                    this.$modal.find("#fileInportButtons").removeClass("d-flex");
                }
            };

            this.currentUploader = uploadElement.kendoUpload(kendoUploadOptions).data("kendoUpload");
        }

        initializeUpload(options) {
            if (!this.initialized) {
                console.error('FileUploadService no está inicializado');
                return;
            }

            const settings = {
                url: "",
                title: "Subir archivo",
                allowedExtensions: [".xlsx", ".xls"],
                paramName: "file",
                multiple: false,
                onSuccess: function () { },
                onError: function () { },
                onComplete: function () { },
                ...options
            };

            this.currentSettings = settings;
            this.updateModalTitle(settings.title);
            this.clearResults();
            this.setupFileUploader(settings);
            this.openModal();
        }

        handleFileUpload(e, settings) {
            e.preventDefault();
            const files = e.files;
            const formData = new FormData();
            formData.append(settings.paramName, files[0].rawFile);

            this.$modal.find("#loadingSpinner").show();
            this.$modal.find("#uploadResults, #errorDetails").hide();

            $.ajax({
                url: this.getUrl(settings.url),
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                success: (response) => {
                    settings.onSuccess(response);
                    this.showUploadResults(response);
                },
                error: (jqXHR) => {
                    const message = jqXHR.responseJSON?.message || "Error al procesar el archivo";
                    Alert.error(message);
                    settings.onError(jqXHR);
                },
                complete: () => {
                    this.$modal.find("#loadingSpinner").hide();
                    settings.onComplete();
                }
            });
        }

        showUploadResults(response) {
            const { inserted, updated, errorRecords, errors } = response;
            this.$modal.find("#insertedRecords").text(inserted || 0);
            this.$modal.find("#modifiedRecords").text(updated || 0);
            this.$modal.find("#errors").text(errors || 0);

            if (errorRecords?.length) {
                let errorDetailsHtml = "<ul>";
                errorRecords.forEach(error => {
                    errorDetailsHtml += `<li>${error.error}</li>`;
                });
                errorDetailsHtml += "</ul>";
                this.$modal.find("#errorDetails").html(errorDetailsHtml);
            }
            this.$modal.find("#uploadResults").show();
        }

        clearResults() {
            this.$modal.find("#uploadResults, #errorDetails").hide();
            this.$modal.find("#insertedRecords, #modifiedRecords, #errors").text("");
            this.$modal.find("#errorDetails").html("");
            this.$modal.find("#fileInportButtons").removeClass("d-flex");

            const uploadContainerElement = this.$modal.find(".demo-section");
            uploadContainerElement.empty();
            uploadContainerElement.html('<input type="file" name="kendoFileUpload" id="kendoFileUpload" />');

            this.isSelecting = false;

            if (this.currentSettings) {
                this.setupFileUploader(this.currentSettings);
            }
        }

        updateModalTitle(title) {
            if (this.kendoWindow) {
                this.kendoWindow.setOptions({ title });
            }
        }

        getUrl(url) {
            return typeof url === 'function' ? url() : url;
        }

        openModal() {
            if (this.kendoWindow) {
                this.kendoWindow.center().open();
            }
        }
    }

    return {
        getInstance: function () {
            if (!instance) {
                if (typeof kendo === 'undefined') {
                    console.error('Kendo UI no está disponible');
                    return null;
                }
                instance = new FileUploader();
                instance.initialize();
            }
            return instance;
        }
    };
})(jQuery);

/*
// Ejemplo de uso del Componente FileUploadService

// 1. Obtener la instancia del servicio
const uploader = FileUploadService.getInstance();

// 2. Ejemplos de implementación para diferentes casos de uso

// Ejemplo básico
$("#btn-importarZonaMaterial").on("click", function () {
    uploader.initializeUpload({
        // URL dinámica con parámetro
        url: () => `/Drafts/ImportarZonaMaterial?paqueteId=${$("#cmbPaquetes").val()}`,
        title: "Importar Zona Material",
        allowedExtensions: [".xlsx", ".xls"],
        onSuccess: function (response) {
            if (response.inserted > 0 || response.updated > 0) {
                getTablaZonas();
            }
        },
        onError: function (jqXHR) {
            console.error("Error durante la carga:", jqXHR);
        },
        onComplete: function () {
            console.log("Carga completa.");
        }
    });
});

// Ejemplo con validación de tamaño máximo usando las opciones de Kendo Upload
$("#btn-importarDocumentos").on("click", function () {
    uploader.initializeUpload({
        url: "/api/documentos/importar",
        title: "Importar Documentos",
        allowedExtensions: [".xlsx", ".xls", ".pdf", ".doc", ".docx"],
        // Configurar tamaño máximo (5MB)
        maxFileSize: 5 * 1024 * 1024,
        onSuccess: function (response) {
            Alert.success(`Se importaron ${response.inserted} documentos correctamente`);
            actualizarListaDocumentos();
        },
        onError: function (jqXHR) {
            Alert.error("Error al importar documentos");
        }
    });
});

// Ejemplo completo con manejo de eventos y parámetros adicionales
$("#btn-importarMunicipios").on("click", function () {
    uploader.initializeUpload({
        // URL con función que permite obtener parámetros dinámicamente
        url: () => {
            const paqueteId = $("#cmbPaquetes").val();
            const userId = $("#userId").val();
            return `/Drafts/ImportarMunicipios?paqueteId=${paqueteId}&userId=${userId}`;
        },
        title: "Importar Municipios",
        allowedExtensions: [".xlsx", ".xls"],
        // Parámetros adicionales que se enviarán en el POST
        data: {
            userId: $("#userId").val(),
            tipoOperacion: "importacion"
        },
        onSuccess: function (response) {
            if (response.inserted > 0 || response.updated > 0) {
                Alert.success("Importación exitosa");
                getTablaMunicipios();
            } else {
                Alert.warning("No se realizaron cambios");
            }
        },
        onError: function (jqXHR) {
            const message = jqXHR.responseJSON?.message || "Error al importar municipios";
            Alert.error(message);
            console.error("Error detallado:", jqXHR);
        },
        onComplete: function () {
            // Acciones que siempre se ejecutan al finalizar
            hideLoading();
            refreshDashboard();
        }
    });
});
*/